<template>
  <v-container fluid pa-0 fill-height>
    <v-row no-gutters class="text-center justify-center fill-height">
      <v-col
        cols="12"
        sm="12"
        xs="12"
        md="3"
        lg="3"
        xl="3"
        class="align-self-center"
      >
        <v-row no-gutters class="text-center justify-center mt-6">
          <v-col cols="auto">
            <v-img
              src="../assets/SPPlusMetropolisLogo.svg"
              width="100%"
              height="100%"
            >
            </v-img>
          </v-col>
        </v-row>
        <v-card tile flat class="pa-4">
          <v-row no-gutters class="text-center justify-center mt-6 mb-2">
            <v-col>
              <v-text-field
                filled
                autofocus
                class="no-border-bottom"
                :rules="emailRules"
                hide-details="auto"
                placeholder="Email address"
                v-model="account.email"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row no-gutters class="text-center justify-center">
            <v-col>
              <v-text-field
                filled
                v-model="account.password"
                class="no-border-bottom"
                placeholder="Password"
                :append-icon="showPassword ? 'visibility' : 'visibility_off'"
                hide-details
                :type="showPassword ? 'text' : 'password'"
                name="password"
                counter
                v-on:keyup.enter="login"
                @click:append="showPassword = !showPassword"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row no-gutters class="mt-6">
            <v-col>
              <v-btn block x-large class="elevation-0 primary" @click="login" :loading="loading"
                >Log in</v-btn
              >
            </v-col>
          </v-row>
          <v-row no-gutters class="mt-6">
            <v-col>
              <a class="text-caption cursor" @click="forgotPassword"
                >Forgot Password?</a
              >
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-snackbar v-model="showSnackBar" :color="snackBarColor">
      {{ snackBarText }}
      <template v-slot:action="{ attrs }">
        <v-btn
          color="primary"
          text
          v-bind="attrs"
          @click="showSnackBar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <!-- <loading-overlay-mask :loading="loading"></loading-overlay-mask> -->
  </v-container>
</template>

<script>
// import * as userRolesAPI from "@/api/userRolesAPI";
export default {
  name: "LoginView",
  components: {},
  data() {
    return {
      account: {
        email: "",
        password: "",
      },
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
      showPassword: false,
      showSnackBar: false,
      snackBarText: "",
      snackBarColor: "",
    };
  },
  computed: {
    loading() {
      return this.$store.getters["pinaStore/getterLoadingStatus"]("LOGIN");
    },
  },
  watch: {},
  mounted() {
    this.$store.commit("pinaStore/SET_LOADING_STATUS", {
      property: "LOGIN",
      pending: false,
    });
  },
  methods: {
    /**
     * @method login dispatch login action with the provided credentials.
     */
    async login() {
      try {
        let isloggedIn = await this.$store.dispatch("authStore/login", {
          me: this.account.email,
          pwp: this.account.password,
          ltype: "CA"
        });
        this.showSnackBar = !isloggedIn.data.status;
        this.snackBarText = !isloggedIn.data.status ? isloggedIn.data.error_message : "";
        this.snackBarColor = !isloggedIn.data.status ? "red" : "green";
        if (isloggedIn?.data?.status) {
          isloggedIn?.data?.status && isloggedIn.data.status
            ? this.$router.replace({ path: 'home' })
            : "";
        }
      } catch (error) {
        console.log(error);
      }
    },
    forgotPassword() {
      this.$router.replace({ path: "/forgotpassword" });
    },
  },
};
</script>
<style lang="sass">
.no-border-bottom
  border-bottom: 0px
</style>